import React from 'react';
import Drawer from '@mui/material/Drawer';
import {styled} from '@mui/material';
import {Route, Routes} from 'react-router-dom';
import {GlobalStyle} from './global-style';
import {Header} from './header';
import {DesktopNavigation} from './navigation';
import {WyswigView} from './wyswig-view';
import {useAppProps} from '../App';
import {VotePage} from './pages/vote';
import {AuthDialog} from './auth-dialog';
import {Footer} from './footer';
import {Partners} from './pages/partners';
import {WatchPage} from './pages/watchPage';
import {Index} from './pages';

const DesktopDrawer = styled(Drawer)`
    & .MuiDrawer-paper {
        box-sizing: border-box;
        width: 460px;
    }

    //display: block;
    //@media (min-height: 599px) {
    //  display: none;
    //}
`;

const MobileDrawer = styled(DesktopDrawer)`
    //display: none;
    //@media (min-height: 599px) {
    //  display: block;
    //}
`;

const DrawerRoot = styled('div')`

`;

export const DrawerContents: React.FC = () => {
    return <DrawerRoot>
        Hello!
    </DrawerRoot>;
};

const RootDiv = styled('div')`
    width: 100vw;
    min-height: 100vh;
`;

const PageDiv = styled('div')`
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    max-width: 1344px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const PageContentsDiv = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 30px;
`;

const InnerPageContentsDiv = styled('div')`
    max-width: 100%;
    padding-top: 20px;
    flex: 1;
    overflow: hidden;
`;

const NotRootPage: React.FC<React.PropsWithChildren<any>> = ({children}) => {
    return <PageContentsDiv>
        <DesktopNavigation/>
        <InnerPageContentsDiv>
            {children}
        </InnerPageContentsDiv>
    </PageContentsDiv>;
};

export const Root: React.FC = () => {
    const appProps = useAppProps();
    
    return <RootDiv>
        <AuthDialog/>
        <GlobalStyle/>
        <PageDiv>
            <Header/>
            <Routes>
                <Route index element={<Index/>}/>
                <Route path="/rules"
                       element={<NotRootPage>
                           <WyswigView
                                   dangerouslySetInnerHTML={{__html: appProps.pageData?.content}}/>
                       </NotRootPage>}/>
                <Route path="/vote"
                       element={<NotRootPage><VotePage/></NotRootPage>}/>
                <Route path="/partners"
                       element={<NotRootPage><Partners/></NotRootPage>}/>
                <Route path="/winners"
                       element={<NotRootPage><WyswigView
                               dangerouslySetInnerHTML={{__html: appProps.pageData?.content}}/></NotRootPage>}/>
                <Route path="/jury"
                       element={<NotRootPage><WyswigView
                               dangerouslySetInnerHTML={{__html: appProps.pageData?.content}}/></NotRootPage>}/>
                <Route path="/contacts"
                       element={<NotRootPage><WyswigView
                               dangerouslySetInnerHTML={{__html: appProps.pageData?.content}}/></NotRootPage>}/>
                <Route path="/exhibition"
                       element={<NotRootPage><WyswigView
                               dangerouslySetInnerHTML={{__html: appProps.pageData?.content}}/></NotRootPage>}/>
                <Route path="/watch/:watchId"
                       element={<NotRootPage><WatchPage/></NotRootPage>}/>
            </Routes>
            <Footer/>
        </PageDiv>
    </RootDiv>;
};
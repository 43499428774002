import React, {useState} from 'react';
import {Dialog, styled} from '@mui/material';
import {Carousel} from 'react-responsive-carousel';
import {useAppProps} from '../../App';
import {Watch} from '../../tools/front-props';
import {WyswigView} from '../wyswig-view';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .carousel-root {
    box-sizing: content-box;
    position: relative;
    float: left;
    top: 0;
    left: 0;
    width: 50%;
    height: 0;
    padding-bottom: 50%;
    border: 1px solid black;

    @media (max-width: 600px) {
      width: 90%;
      padding-bottom: 90%;
    }
  }
`;

const WatchImg = styled('div')`
  > img {
    object-fit: contain;
    max-height: calc(100vh - 64px);
    overflow: hidden;
  }
`;

const WatchTitlesContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

const WatchName = styled('h1')`
    font-family: NotoSans, serif;
    margin: 10px 0 5px;
    font-size: 20px;
`;

const WatchBrand = styled('h2')`
    font-family: NotoSans, serif;
    font-size: 16px;
    color: gray;
    margin-bottom: 10px;
    margin-top: 0;
`;

const SeparatorLine = styled('div')`
  margin: 20px 0;
  height: 1px;
  width: 100%;
  background: black;
`;

const WatchDataTable = styled('table')`
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;

  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;

  & th {
    text-align: right;
    width: 1%;
    white-space: nowrap;
  }

  & td {
    padding: 2px 10px;
  }
`;

const CategoryBannerContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const CategoryBannerImgContainer = styled('a')`
  display: block;
  flex: 1 1 auto;
  height: 60px;
`;

const CategoryBannerImg = styled('img')`
  object-fit: cover;
  height: 100%;
  max-width: 100%;
`;

const Message = styled('span')`
  flex: 0 1 50%;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
`;

const CarouselContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShareButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
`;

const ShareButtonIcon = styled('a', {
    shouldForwardProp: (prop) => prop !== 'img',
})<{ img: string }>`
  width: 32px;
  height: 32px;
  margin: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${props => props.img});
`;

export const WatchPage: React.FC = () => {
    const {pageData, siteData: {publicUrl}} = useAppProps();
    const watch = pageData as Watch;
    const [selectedImage, setSelectedImage] = useState('');

    const [fullScreenDialogOpen, setFullScreenDialogOpen] = useState(false);

    const tgShare = `https://t.me/share/url?url=${encodeURIComponent(`${publicUrl}/watch/${watch.id}`)}&text=${encodeURIComponent(`Голосуйте за часы ${watch.name}`)}`;
    const vkShare = `https://vk.com/share.php?url=${encodeURIComponent(`${publicUrl}/watch/${watch.id}`)}&title=${encodeURIComponent(`Голосуйте за часы ${watch.name}`)}`;


    return <Root>
        <Dialog scroll="paper" maxWidth={false} open={fullScreenDialogOpen}
                onClose={() => setFullScreenDialogOpen(false)}
                onTransitionExited={() => {
                    setSelectedImage('');
                }}>
            <img alt={'Изображение часов'} src={selectedImage} onClick={() => {
                setFullScreenDialogOpen(false);
            }}/>
        </Dialog>
        <WatchTitlesContainer>
            <WatchName>
                {watch.name}
            </WatchName>
            <WatchBrand>
                {watch.brand}
            </WatchBrand>
        </WatchTitlesContainer>
        <CarouselContainer>
            <Carousel showIndicators={true}
                      infiniteLoop={true}
                      showStatus={false}
                      showArrows={true}
                      showThumbs={false}
                      onClickItem={(index) => {
                          setSelectedImage(watch.images[index]);
                          setFullScreenDialogOpen(true);
                      }}>
                {watch.images.map(image => <WatchImg key={image}>
                        <img alt={'Изображение часов'} src={image}/>
                    </WatchImg>,
                )}
            </Carousel>
            <ShareButtonsContainer>
                <ShareButtonIcon img={'/icons/telegram.svg'}
                                 target="_blank"
                                 href={tgShare}/>
                <ShareButtonIcon img={'/icons/vk.svg'} target="_blank" href={vkShare}/>
            </ShareButtonsContainer>
        </CarouselContainer>
        <SeparatorLine/>
        <WatchDataTable>
            <tbody>
            {watch.articul !== '' &&
                <tr>
                    <th>
                        Артикул
                    </th>
                    <td>
                        {watch.articul}
                    </td>
                </tr>
            }
            {watch.size !== '' &&
                <tr>
                    <th>
                        Размер
                    </th>
                    <td>
                        {watch.size}
                    </td>
                </tr>
            }
            {watch.collection !== '' &&
                <tr>
                    <th>
                        Коллекция
                    </th>
                    <td>
                        {watch.collection}
                    </td>
                </tr>
            }
            {watch.mechanizm !== '' &&
                <tr>
                    <th>
                        Механизм
                    </th>
                    <td>
                        {watch.mechanizm}
                    </td>
                </tr>
            }
            {watch.price !== '' &&
                <tr>
                    <th>
                        Цена
                    </th>
                    <td>
                        {watch.price}
                    </td>
                </tr>
            }
            </tbody>
        </WatchDataTable>
        <SeparatorLine/>
        <WyswigView dangerouslySetInnerHTML={{__html: watch.descriptionHtml}}/>
        <CategoryBannerContainer>
            <Message>
                Организовать номинацию помогли:
            </Message>
            <CategoryBannerImgContainer href={watch.category.banner.link}>
                <CategoryBannerImg src={watch.category.banner.image}/>
            </CategoryBannerImgContainer>
        </CategoryBannerContainer>
    </Root>;
};

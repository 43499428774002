import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {CacheProvider} from '@emotion/react';
import {CssBaseline} from '@mui/material';
import App from './App';
import createEmotionCache from './tools/create-emotion-cache';

const cache = createEmotionCache();

export const Main: React.FC = () => {
    return <BrowserRouter>
        <CacheProvider value={cache}>
            <CssBaseline/>
            <App/>
        </CacheProvider>
    </BrowserRouter>;
};

ReactDOM.hydrateRoot(
    document.getElementById('root') as HTMLElement,
    <Main/>,
);

import React from 'react';
import {styled} from '@mui/material';
import {useAppProps} from '../../App';
import {RootPageData} from '../../tools/front-props';
import {Navigation} from '../navigation';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PartnersGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(min(250px, 100%)), 1fr));
  grid-gap: 20px;
  margin-top: 20px;
`;

const PartnerSquare = styled('a')`
  position: relative;
  float: left;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border: 1px solid black;
`;

const PartnerSquareCaption = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 20%;
  padding: 10px;
  background: #000000aa;
  font-size: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PartnerLogo = styled('div')`
  position: absolute;
  top: 50%;
  margin-bottom: auto;
  bottom: -50%;
  width: 100%;
  transform: translateY(-50%);

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const TopBanner = styled('div')`
  min-height: 360px;
  width: 100%;
  position: relative;
  padding: 40px;
  gap: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & > div {
    z-index: 10;
  }
`;

const DesktopStyledNavigation = styled(Navigation)`
  padding: 20px;
  background: #ffffffdd;
  height: fit-content;
  @media (max-width: 600px) {
    display: none;
  }
`;

const ProjectDescription = styled('div')`
  padding: 20px;
  background: #ffffffdd;
  font-size: 18px;
  max-width: 500px;
`;


export const Index: React.FC = () => {
    const {pageData} = useAppProps();
    const mainPageData = pageData as RootPageData;
    const {mainGrid} = mainPageData;

    return <Root>
        <TopBanner>
            <img src={mainPageData.mainBgImage} alt={'Баннер'}/>
            <DesktopStyledNavigation/>
            <ProjectDescription dangerouslySetInnerHTML={{__html: mainPageData.description}}/>
        </TopBanner>
        <PartnersGrid>
            {mainGrid.map(gridItem => {
                return <PartnerSquare key={gridItem.image} href={gridItem.link}>
                    <PartnerLogo>
                        <img src={gridItem.image} alt={'Партнер'}/>
                    </PartnerLogo>
                    <PartnerSquareCaption>
                        {gridItem.title}
                    </PartnerSquareCaption>
                </PartnerSquare>;
            })}
        </PartnersGrid>
    </Root>;
};
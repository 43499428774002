import React, {useCallback, useState} from 'react';
import {Button, Snackbar, styled} from '@mui/material';
import {useAppProps, useModals} from '../../App';
import {VotePageProps, Watch} from '../../tools/front-props';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WatchesGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(min(250px, 100%)), 1fr));
  margin-top: 20px;
  grid-gap: 20px;
`;

const WatchBlock = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
`;

const WatchImg = styled('div')`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border: 1px solid black;

  a {
    position: absolute;
    top: 50%;
    margin-bottom: auto;
    bottom: -50%;
    width: 100%;
    transform: translateY(-50%);

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

const VoteCategoryRoot = styled('div')`
  width: 100%;
  margin-top: 20px;
`;

const CategoryTop = styled('div')`
  text-transform: uppercase;
  color: black;
  border-bottom: 1px solid #aaaaaa;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  gap: 10px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const CategoryTitle = styled('h3')`
  margin: 0;
  font-size: 1.5em;
  flex: 1 0 200px;
`;

const CategoryBannerContainer = styled('a')`
  flex: 1 1 auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
`;


const CategoryBanner = styled('img')`
  object-fit: cover;
  height: 100%;
  max-width: 100%;
`;

const WatchBrand = styled('h4')`
    font-family: NotoSans, serif;
    text-decoration: none;
    color: black !important;
    margin: 10px 0 5px;
`;

const WatchName = styled('span')`
    font-family: NotoSans, serif;
    color: gray;
    margin-bottom: 10px;
`;

const NeedEmailNotification = styled('span')`
  font-size: 16px;
  text-align: start;
  width: 100%;
  margin-bottom: 10px;
`;

const NeedEmailNotificationA = styled('span')`
  cursor: pointer;
  text-decoration: underline;
  color: mediumblue;
`;

const NominationsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding-top: 20px;
`;

const NominationsList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NominationLink = styled('a')`
  text-decoration: none;
  color: #6F6F6E;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
`;

const VoteInfoText = styled('p')`
  overflow: hidden;
  margin: 0;
  font-size: 16px;
  flex: 0 0 50%;
`;

const Alert = styled('div')`
  padding: 16px 32px;
  color: white;
  border: 2px solid white;
  box-shadow: 0 0 60px 8px black;
  background: #142d74;
  border-radius: 12px;
  font-size: 18px;
`;


export const VotePage: React.FC = () => {
    const {pageData, email, siteData} = useAppProps();
    const {authEnabled} = siteData;
    const {setAuthModalOpen} = useModals();
    const {voteCategories, votePage} = pageData as VotePageProps;
    const [votedIds, setVotedIds] = useState([] as string[]);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);


    const voteForWatch = useCallback(
        async (watch: Watch) => {
            if (email === '') {
                setAuthModalOpen(true);
                return;
            }
            const categoryId = voteCategories.find(it => it.watches.find(w => w.id === watch.id))?.id;
            if (!categoryId)
                return;

            const alreadyVotedInSameCat = votedIds.find(votedId => voteCategories.find(it => it.watches.find(w => w.id === votedId))?.id === categoryId);
            setVotedIds([...votedIds.filter(it => it !== alreadyVotedInSameCat), watch.id]);

            setSnackbarOpen(true);

            try {
                fetch('/api/vote', {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({watchId: watch.id}),
                });
            } catch (e) {
                console.error(e);
            }
        },
        [email, voteCategories, votedIds, setVotedIds],
    );

    const logout = useCallback(
        async () => {
            const form = document.createElement('form');
            document.body.appendChild(form);
            form.action = '/auth/logout';
            form.method = 'post';
            form.submit();
        },
        [],
    );


    return <Root>
        <Snackbar open={snackbarOpen} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                  onClose={() => setSnackbarOpen(false)}>
            <Alert>
                Ваш голос учтен!
            </Alert>
        </Snackbar>
        {authEnabled && email === '' && <NeedEmailNotification>
            Чтобы проголосовать, <NeedEmailNotificationA onClick={() => {
            setAuthModalOpen(true);
        }}>укажите свою
            почту</NeedEmailNotificationA>
        </NeedEmailNotification>}
        {email !== '' && <NeedEmailNotification>
            Вы вошли как <b>{email}</b>. <NeedEmailNotificationA onClick={logout}> Выйти</NeedEmailNotificationA>
        </NeedEmailNotification>}
        <NominationsContainer>
            <NominationsList>
                {voteCategories.map(voteCategory => {
                    return <NominationLink href={`#${voteCategory.title}`} key={voteCategory.title}>
                        {voteCategory.title}
                    </NominationLink>;
                })}
            </NominationsList>
            <VoteInfoText dangerouslySetInnerHTML={{__html: votePage.topNote}}/>
        </NominationsContainer>
        {voteCategories.map(voteCategory => <VoteCategoryRoot key={voteCategory.title}>
            <CategoryTop>
                <CategoryTitle id={voteCategory.title}>
                    {voteCategory.title}
                </CategoryTitle>
                <CategoryBannerContainer href={voteCategory.banner.link}>
                    <CategoryBanner src={voteCategory.banner.image}/>
                </CategoryBannerContainer>
            </CategoryTop>
            <WatchesGrid>
                {voteCategory.watches.map(watch => {
                    const voted = votedIds.includes(watch.id);
                    return <WatchBlock key={watch.id}>
                        <WatchImg>
                            <a href={`/watch/${watch.id}`}>
                                <img src={watch.images[0]}/>
                            </a>
                        </WatchImg>
                        <a href={`/watch/${watch.id}`}>
                            <WatchBrand>
                                {watch.brand}
                            </WatchBrand>
                        </a>
                        <WatchName>
                            {watch.name}
                        </WatchName>
                        {siteData.voteActive &&
                            <Button variant={'contained'} onClick={() => voteForWatch(watch)}
                                    disabled={voted || !watch.wotable}>
                                {watch.wotable ? (voted ? 'Выбрано!' : 'Проголосовать') : 'Недоступно'}
                            </Button>
                        }
                    </WatchBlock>;
                })}
            </WatchesGrid>
        </VoteCategoryRoot>)}
    </Root>;
};
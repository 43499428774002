import React, {useState} from 'react';
import {FrontProps} from './tools/front-props';
import {createHook} from './tools/create-hook';
import {Root} from './components/root';

const appPropsHook = createHook((appProps: FrontProps) => appProps);
const AppPropsProvider = appPropsHook.provider;
export const useAppProps = appPropsHook.hook;


const modalsHook = createHook(() => {
    const [authModalOpen, setAuthModalOpen] = useState(false);

    return {
        authModalOpen,
        setAuthModalOpen,
    };
});
const ModalsHookProvider = modalsHook.provider;
export const useModals = modalsHook.hook;

export const App: React.FC<{ props?: FrontProps }> = ({props}) => {
    // @ts-ignore
    return <AppPropsProvider initialValue={props || window.INITIAL_STATE}>
        <ModalsHookProvider>
            <Root/>
        </ModalsHookProvider>
    </AppPropsProvider>;
};

export default App;

import React, {useRef, useState} from 'react';
import {styled, SwipeableDrawer} from '@mui/material';
import {Link} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import {useAppProps} from '../App';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  color: black;

  font-size: 18px;
  text-transform: uppercase;

  :hover {
    color: gray;
  }
`;

export const Navigation: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props) => {
    const reloadDocument = useRef(true).current;
    return <Root {...props}>
        <StyledLink to={'/'} reloadDocument={reloadDocument}>
            Главная
        </StyledLink>
        <StyledLink to={'/rules'} reloadDocument={reloadDocument}>
            Правила
        </StyledLink>
        <StyledLink to={'/vote'} reloadDocument={reloadDocument}>
            Голосование
        </StyledLink>
        <StyledLink to={'/jury'} reloadDocument={reloadDocument}>
            Жюри
        </StyledLink>
        <StyledLink to={'/partners'} reloadDocument={reloadDocument}>
            Партнеры
        </StyledLink>
        <StyledLink to={'/winners'} reloadDocument={reloadDocument}>
            Победители
        </StyledLink>
        <StyledLink to={'/exhibition'} reloadDocument={reloadDocument}>
            Выставка
        </StyledLink>
        <StyledLink to={'/contacts'} reloadDocument={reloadDocument}>
            Контакты
        </StyledLink>
    </Root>;
};

const MobileNavigationButton = styled(MenuIcon)`
  display: none;
  flex: 1 2 100px;
  height: 80%;
  @media (max-width: 600px) {
    display: block;
  }
`;


const PaddedNavigation = styled(Navigation)`
  padding: 20px;
`;

export const MobileNavigation: React.FC = () => {
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

    return <>
        <MobileNavigationButton onClick={() => {
            setMobileDrawerOpen(true);
        }}/>
        <React.Fragment>
            <SwipeableDrawer
                anchor={'right'}
                open={mobileDrawerOpen}
                onClose={() => setMobileDrawerOpen(false)}
                onOpen={() => setMobileDrawerOpen(true)}
            >
                <PaddedNavigation onClick={() => {
                    setMobileDrawerOpen(false);
                }}/>
            </SwipeableDrawer>
        </React.Fragment>
    </>;
};

const DesktopNavigationRoot = styled('div')<{ burl: string }>`
  flex: 0 0 25%;
  @media (max-width: 600px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 460px;
  background-image: url(${props => props.burl});
`;

const DesktopStyledNavigation = styled(Navigation)`
  padding: 10px;
  background: #ffffffaa;
`;


export const DesktopNavigation: React.FC = () => {
    const {siteData: {menuBackground}} = useAppProps();
    return <DesktopNavigationRoot burl={menuBackground}>
        <DesktopStyledNavigation/>
    </DesktopNavigationRoot>;
};
import React from 'react';

export const createHook = <T, Arg>(initState: (arg: Arg) => T) => {
    // @ts-ignore
    const context = React.createContext();

    return {
        hook: () => {
            const localContext = React.useContext(context);
            if (localContext === undefined) {
                throw new Error('wff');
            }
            return localContext as T;
        },
        // @ts-ignore
        provider: (({children, initialValue = {}}) => <context.Provider
            value={initState(initialValue as Arg)}>{children}</context.Provider>) as React.FC<Arg extends {} ? React.PropsWithChildren<{ initialValue: Arg }> : React.PropsWithChildren>,
    };
};
import React from 'react';
import {styled} from '@mui/material';
import {useAppProps} from '../App';
import {MobileNavigation} from './navigation';


const Root = styled('div')``;


const HeaderRoot = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 22vw;
  max-height: 100px;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;

const StyledLinkMain = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 auto;
  overflow: hidden;

  > img {
    object-fit: contain;
    overflow: hidden;
    max-height: 100px;
  }

  @media (max-width: 600px) {
    flex: 1 1 100px;
  }
`;

const StyledLinkMainGrow = styled(StyledLinkMain)`
  flex: 1 1 auto !important;
`;

const SponsorA = styled(StyledLinkMain)`
  max-height: 70px;
  @media (max-width: 600px) {
    display: none;
  }

  > img {
    max-height: 70px !important;
  }
`;

const DesktopBannerA = styled('a')`
  @media (max-width: 600px) {
    display: none;
  }
`;
const DesktopBanner = styled('img')`
  width: 100%;
  object-fit: contain;
  max-height: 100px;
  margin-bottom: 10px;
`;
const MobileBannerA = styled('a')`
  margin: 10px 0;
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;
const MobileBanner = styled(DesktopBanner)``;

const CategoryBannerContainer = styled('a')`
  display: none;
  width: 100%;
  height: 70px;
  @media (max-width: 600px) {
    display: block;
  }
`;

const CategoryBanner = styled('img')`
  object-fit: cover;
  overflow: hidden;
  height: 100%;
  width: 100%
`;

const BottomLine = styled('div')`
  margin-top: 20px;
  height: 1px;
  width: 100%;
  background: black;
`;


export const Header: React.FC = () => {
    const {siteData: {sponsors, mainSponsorBanner, mainSponsorMobileBanner}} = useAppProps();
    return <Root>
        <DesktopBannerA href={mainSponsorBanner.link}>
            <DesktopBanner src={mainSponsorBanner.image}/>
        </DesktopBannerA>
        <HeaderRoot>
            <StyledLinkMain href={'/'}>
                <img src={'/logo.png'} alt={'Лого'}/>
            </StyledLinkMain>
            <StyledLinkMainGrow href={'/'}>
                <img src={'/logo-text.svg'} alt={'Лого'}/>
            </StyledLinkMainGrow>
            {sponsors.map(sponsor => <SponsorA key={sponsor.image} href={sponsor.link}>
                <img src={sponsor.image} key={sponsor.image} alt={'Спонсор'}/>
            </SponsorA>)}
            <MobileNavigation/>
        </HeaderRoot>
        {mainSponsorMobileBanner ?
            <CategoryBannerContainer href={mainSponsorMobileBanner.link}>
                <CategoryBanner src={mainSponsorMobileBanner.image}/>
            </CategoryBannerContainer> : <CategoryBannerContainer href={mainSponsorBanner.link}>
                <CategoryBanner src={mainSponsorBanner.image}/>
            </CategoryBannerContainer>}
        <BottomLine/>
    </Root>;
};
import React, {useCallback, useMemo, useState} from 'react';
import {Button, Dialog, LinearProgress, styled, TextField} from '@mui/material';
import {useAppProps, useModals} from '../App';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
`;

const Title = styled('span')`
  font-size: 16px;
`;

const SentTitle = styled(Title)`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const Caption = styled('span')`
  font-size: 12px;
`;

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};

const StyledProgress = styled(LinearProgress)`
  width: 100%;
  height: 2px;
`;


export const AuthDialog: React.FC = () => {
    const {setAuthModalOpen, authModalOpen} = useModals();
    const {siteData: {publicUrl, paths}} = useAppProps();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(undefined as undefined | 'bademail' | 'network');
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const textFieldErrorText = useMemo(() => {
        switch (error) {
            case 'bademail':
                return 'Введите корректный e-mail';
            case 'network':
                return 'Ошибка сети, повторите позже';
            default:
                return undefined;
        }
    }, [error]);

    const authorize = useCallback(
        async () => {
            try {
                if (!validateEmail(email)) {
                    setError('bademail');
                    return;
                }
                setLoading(true);
                const resp = await fetch(paths.emailLoginPost, {
                    method: 'POST',
                    headers: {'Content-type': 'application/json'},
                    body: JSON.stringify({destination: email}),
                });
                setSent(true);
            } catch (e) {
                console.error(e);
                setError('network');
            } finally {
                setLoading(false);
            }
        },
        [setError, email],
    );


    return <Dialog
        open={authModalOpen}
        onClose={() => {
            if (!loading)
                setAuthModalOpen(false);
        }}
    >
        <Root>
            {loading ? <StyledProgress variant={'indeterminate'}/> : <div style={{height: 2}}/>}
            {sent ? <>
                <SentTitle>
                    Пожалуйста, перейдите по ссылке, отправленной на почту <b>{email}</b>
                </SentTitle>
            </> : <>
                <Title>
                    Чтобы избежать накруток и обеспечить принцип «один человек – один голос», просим указать и
                    подтвердить ваш e-mail адрес
                </Title>
                <TextField
                    fullWidth
                    label="E-Mail"
                    type={'email'}
                    value={email}
                    error={textFieldErrorText !== undefined}
                    helperText={textFieldErrorText || ' '}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setError(undefined);
                    }}
                />
                <Caption>
                    Подтверждая почту, вы соглашаетесь получать новости о часах
                </Caption>
                <Button variant={'contained'} onClick={authorize} disabled={loading}>
                    Подтвердить
                </Button>
            </>}
        </Root>
    </Dialog>;
};
import React from 'react';
import {styled} from '@mui/material';
import {useAppProps} from '../../App';
import {PartnersPageData} from '../../tools/front-props';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PartnersGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(min(250px, 100%)), 1fr));
  grid-gap: 20px;
`;

const PartnerSquare = styled('a')`
  position: relative;
  float: left;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border: 1px solid black;
`;

const PartnerSquareCaption = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 20%;
  padding: 10px;
  background: #000000aa;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PartnerLogo = styled('div')`
  position: absolute;
  top: 50%;
  margin-bottom: auto;
  bottom: -50%;
  width: 100%;
  transform: translateY(-50%);

  & > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const CategoryTitle = styled('div')`
  margin: 20px 0;
  font-size: 16px;
`;

const MainPartnerContainer = styled('div')`
  height: 300px;
  width: 100%;
`;

const MainPartnerSquare = styled(PartnerSquare)`
  position: relative;
  padding-bottom: 0;
  height: 100%;
`;


export const Partners: React.FC = () => {
    const {pageData} = useAppProps();
    const partners = pageData as PartnersPageData;
    const {mainPartner} = partners;

    return <Root>
        <CategoryTitle>
            Генеральный партнёр
        </CategoryTitle>
        <MainPartnerContainer>
            <MainPartnerSquare key={mainPartner.image} href={mainPartner.link}>
                <PartnerLogo>
                    <img src={mainPartner.image} alt={'Генеральный партнер'}/>
                </PartnerLogo>
                <PartnerSquareCaption>
                    {mainPartner.name}
                </PartnerSquareCaption>
            </MainPartnerSquare>
        </MainPartnerContainer>
        {partners.partners.map(partnerCat => {
            return <div key={partnerCat.categoryName}>
                <CategoryTitle>
                    {partnerCat.categoryName}
                </CategoryTitle>
                <PartnersGrid key={partnerCat.categoryName}>
                    {partnerCat.partners.map(partner => {
                        return <PartnerSquare key={partner.image} href={partner.link}>
                            <PartnerLogo>
                                <img src={partner.image} alt={'Партнер'}/>
                            </PartnerLogo>
                            <PartnerSquareCaption>
                                {partner.name}
                            </PartnerSquareCaption>
                        </PartnerSquare>;
                    })}
                </PartnersGrid>
            </div>;
        })}
    </Root>;
};
import React from 'react';
import {styled} from '@mui/material';

// https://github.com/directus/directus/blob/main/app/src/interfaces/input-rich-text-html/get-editor-styles.ts
export const WyswigView = styled('div')`
  overflow: hidden;
  width: 100%;

  h1, h2, h3, h4, h5, h6 {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    margin-bottom: 0;
  }

  h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
    margin-top: 0.5em;
  }

  h1 {
    font-size: 36px;
    line-height: 46px;
    margin-top: 1em;
  }

  h2 {
    font-size: 24px;
    line-height: 34px;
    margin-top: 1.25em;
  }

  h3 {
    font-size: 19px;
    line-height: 29px;
    margin-top: 1.25em;
  }

  h4 {
    font-size: 16px;
    line-height: 26px;
    margin-top: 1.5em;
  }

  h5 {
    font-size: 14px;
    line-height: 24px;
    margin-top: 2em;
  }

  h6 {
    font-size: 12px;
    line-height: 22px;
    margin-top: 2em;
  }

  p {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin: 1.5em 0;
  }

  a {
    text-decoration: none;
  }

  ul, ol {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin: 1.5em 0;
  }

  ul ul,
  ol ol,
  ul ol,
  ol ul {
    margin: 0;
  }

  b, strong {
    font-weight: 700;
  }

  code {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    padding: 2px 4px;
    overflow-wrap: break-word;
  }

  pre {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    padding: 1em;
    overflow: auto;
  }

  blockquote {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    border-left: 2px solid gray;
    padding-left: 1em;
    margin-left: 0;
  }

  video,
  iframe,
  img {
    max-width: 100%;
  }

  hr {
    height: 1px;
    border: none;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  table {
    border-collapse: collapse;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
  }

  table th,
  table td {
    border: 1px solid gray;
    padding: 0.4rem;
  }

  figure {
    display: table;
    margin: 1rem auto;
  }

  figure figcaption {
    color: #999;
    display: block;
    margin-top: 0.25rem;
    text-align: center;
  }
`;
import React from 'react';
import {styled} from '@mui/material';
import {useAppProps} from '../App';

const Root = styled('div')`
  border-top: 1px solid black;
  margin-top: 40px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
`;

const PartnersGrid = styled('div')`
  min-height: 100px;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 20px;
  margin-bottom: 20px;
`;

const StyledLinkMainContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLinkMain = styled('a')`
  max-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  > img {
    object-fit: contain;
    overflow: hidden;
    max-height: 100px;
  }
`;

const Message = styled('span')`
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
`;


export const Footer: React.FC = () => {
    const {siteData: {footerPartners}} = useAppProps();
    return <Root>
        <Message>
            Информационные партнеры
        </Message>
        <PartnersGrid>
            {footerPartners.map(footerPartner => {
                return <StyledLinkMainContainer key={footerPartner.image}>
                    <StyledLinkMain href={footerPartner.link}>
                        <img src={footerPartner.image} alt={'Партнер'}/>
                    </StyledLinkMain>
                </StyledLinkMainContainer>;
            })}
        </PartnersGrid>
    </Root>;
};